import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, TextField, Container, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme } from '@mui/material';

import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';
import { sendPostFileData, getDataArray } from '../../system/getData';
import useMediaQuery from "@mui/material/useMediaQuery";

import { GetCompoundAmounts, CompoundDetails } from "../compounds/compoundList";
import ComboBoxFilled from "../globals/comboBoxFilled";
import { useDropzone } from "react-dropzone";

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

export const GeneraFormularioSimple = ({ data, initValues, validateSchemas, reloadData, setModalOpen }) => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const isNonMobile = useMediaQuery("(min-width:600px)");
	const { config, configData } = UseAppConfig();

	const [imagePreview, setImagePreview] = useState(null);
	const formikSetFieldValueRef = useRef(null);

	const onDrop = (acceptedFiles) => {
		const file = acceptedFiles[0];
		if (file) {
			console.log("📸 Archivo seleccionado:", file);
			setImagePreview(URL.createObjectURL(file));
			if (formikSetFieldValueRef.current) {
				formikSetFieldValueRef.current("image", file);
			}
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: "image/*",
		multiple: false,
	});

	const handleFormSubmit = async (values) => {
		const formData = new FormData();

		if (values.image instanceof File) {
			console.log("✅ Archivo válido:", values.image);
			formData.append("image", values.image);
		} else {
			console.error("❌ La imagen no es un archivo válido", values.image);
		}

		Object.keys(values).forEach((key) => {
			if (key !== "image") {
				formData.append(key, values[key]);
			}
		});

		console.log("📡 Enviando FormData...");

		for (let pair of formData.entries()) {
			console.log(pair[0], pair[1]);
		}

		try {
			const result = await sendPostFileData(`${config.API_BASE_URL}/${data.sendData}`, formData);
			console.log("✅ Respuesta del servidor:", result);
			console.log("Formulario actualizado exitosamente:", result);
			enqueueSnackbar(data.msgSuccess, { variant: "success" });

			if (reloadData) {
				reloadData();
			} else {
				navigate(`/${data.module}`);
			}
			if (setModalOpen) {
				setModalOpen(false);
			}
		} catch (error) {
			console.error("❌ Error al subir el archivo:", error);
			if (error.response && error.response.data && error.response.data.error) {
				enqueueSnackbar(error.response.data.error, { variant: "error" });
			} else {
				enqueueSnackbar("El formato del archivo seleccionado es incorrecto.", { variant: "error" });
			}
		}
	};

	return (
		<Box sx={{ width: "100%", borderRadius: "10px", padding: "20px" }}>
			<Header title={data.title} subtitle={data.description} />
			<Formik onSubmit={handleFormSubmit} initialValues={initValues}>
				{({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					setFieldValue,
				}) => {
					// Asignamos el método setFieldValue al ref para usarlo en onDrop
					formikSetFieldValueRef.current = setFieldValue;

					return (
						<form
							onSubmit={(e) => {
								console.log("Formulario intentando enviarse");
								handleSubmit(e);
							}}
						>
							<Grid container spacing={2} alignItems="flex-start">
								{/* Sección de carga de imagen (izquierda) */}
								<Grid item xs={4}>
									<Box
										{...getRootProps()}
										sx={{
											width: "100%",
											height: "300px",
											border: "2px dashed gray",
											borderRadius: "10px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											textAlign: "center",
											cursor: "pointer",
											backgroundColor: "#f9f9f9",
										}}
									>
										<input {...getInputProps()} />
										{imagePreview ? (
											<img
												src={imagePreview}
												alt="Preview"
												style={{
													width: "100%",
													height: "100%",
													objectFit: "cover",
													borderRadius: "10px",
												}}
											/>
										) : (
											<Typography variant="body1" color="textSecondary">
												Arrastra y suelta una imagen aquí o haz clic para seleccionar una.
											</Typography>
										)}
									</Box>
								</Grid>

								{/* Sección del formulario (derecha) */}
								<Grid item xs={8}>
									<Grid container spacing={2}>
										{data.key.map((field, index) => {
											const isTextField = field.formType === "text";
											const isTextArea = field.formType === "textArea";
											const isComboBox = field.formType === "comboBox";

											return isTextArea ? (
												<Grid item xs={12} key={index}>
													<b style={{ marginTop: "20px", display: "block" }}>{field.label}</b>
													<TextField
														fullWidth
														variant="filled"
														multiline
														rows={4}
														onBlur={handleBlur}
														onChange={handleChange}
														value={values[field.name]}
														name={field.name}
														disabled={field.disabled}
														error={!!touched[field.name] && !!errors[field.name]}
														helperText={touched[field.name] && errors[field.name]}
													/>
												</Grid>
											) : (
												<Grid item xs={4} key={index}>
													{isComboBox ? (
														<ComboBoxFilled
															key={index}
															data={field}
															index={index}
															value={values[field.name]}
															onChange={(event) => setFieldValue(field.name, event.target.value)}
														/>
													) : isTextField ? (
														<>
															<b style={{ marginTop: "20px", display: "block" }}>{field.label}</b>
															<TextField
																fullWidth
																variant="filled"
																type="text"
																onBlur={handleBlur}
																onChange={handleChange}
																value={values[field.name]}
																name={field.name}
																disabled={field.disabled}
																error={!!touched[field.name] && !!errors[field.name]}
																helperText={touched[field.name] && errors[field.name]}
															/>
														</>
													) : null}
												</Grid>
											);
										})}
									</Grid>
								</Grid>
							</Grid>

							<Box display="flex" justifyContent="end" mt="20px">
								<Button type="submit" color={data.colorBtn} variant="contained">
									{data.titleBtn}
								</Button>
							</Box>
						</form>
					);
				}}
			</Formik>
		</Box>
	);
};

export const FormatoArticulosCompuestos = ({ data, initValues, validateSchemas }) => {
	const [setRows] = useState([]);
	const navigate = useNavigate();

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const currentDate = new Date();
	const { configData } = UseAppConfig();

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const formattedDate = currentDate.toLocaleDateString('es-ES', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	});

	const handleBack = () => {
		navigate(-1);
	};

	const headerTitle = () => {
		return data.title + ' ' + initValues.descCorta;
	}

	return (
		<Grid>
			<Paper elevation={3} className="panel">
				<Box p={3} className="panel-body">
					<Grid>
						<Box className="invoice-details" mt={3}>
							<Header title={headerTitle()} subtitle={data.description} />
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0px' }}>
								<Button variant="contained" onClick={handleBack} color="success" autoFocus>
									Regresar
								</Button>
							</Box>
							<Box className="invoice-to" mt={3}>
								<Paper style={{ padding: '16px' }}>
									<Grid className="list-unstyled mb0" container spacing={2}>
										<Grid item xs={12} md={2}>
											<img
												src={`/assets/articulos/${initValues.imagen && initValues.imagen.trim() !== "" ? initValues.imagen : "art.jpg"}`}
												width="100%"
												style={{ bottom: '-1px', position: 'relative' }}
												alt="Imagen del artículo"
											/>
										</Grid>

										<Grid item xs={12} md={4}>
											<ul>
												<li><strong>Nombre:</strong> {initValues.descCorta}</li>
												<li><strong>Clave:</strong> {initValues.clave}</li>
												<li><strong>Tipo:</strong> {initValues.tipo}</li>
												<li><strong>Familia:</strong> {initValues.familia}</li>
												<li><strong>Cuenta:</strong> {initValues.cuenta}</li>
												<li><strong>Tipo de Unidad:</strong> por {initValues.unidad}</li>
												<li><strong>Porcentaje Global:</strong> {porcentajeGlobal}%</li>
												<br /><br />
												<li style={{ width: '100%', marginBottom: '35px' }}>
													<strong>Descripción del Servicio:</strong><br />
													{initValues.descLarga}
												</li>
											</ul>
										</Grid>

										<Grid item xs={12} md={6}>
											<GetCompoundAmounts
												data={data}
												tipo={initValues.tipo}
												initValues={initValues}
											/>
										</Grid>
									</Grid>
								</Paper>
								<Box >
									<br /><br />
									<CompoundDetails
										data={data}
										tipo={initValues.tipo}
										initValues={initValues}
									/>
								</Box>
							</Box>
						</Box>
					</Grid>
				</Box>
			</Paper >
		</Grid >
	);
};

export const IconsDefault = ({ tipo, icon = '' }) => {
	const [iconSrc, setIconSrc] = useState('');

	useEffect(() => {
		let resolvedIcon = '';

		if (tipo === 1) tipo = 'Simple';
		if (tipo === 2) tipo = 'Compuesto';

		if (icon.length !== 0) {
			resolvedIcon = icon;
		} else {
			switch (tipo) {
				case 'Categoría':
					resolvedIcon = 'openBox.png';
					break;
				case 'Simple':
					resolvedIcon = 'barCode.png';
					break;
				case 'Compuesto':
					resolvedIcon = 'QR.png';
					break;
				default:
					resolvedIcon = 'defaultIcon.png';
			}
		}

		setIconSrc(`/assets/icons/${resolvedIcon}`);
	}, [tipo, icon]);

	return (
		<img src={iconSrc} width="20px" alt={tipo} />
	);
};