export const newArticle = [
	{
		name: "clave",
		label: "Clave",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "tipoArtID",
		label: "Tipo",
		gridColumn: "span 1",
		formType: "comboBox",
		catalog: "tipo"
	},
	{
		name: "famID",
		label: "Familia",
		gridColumn: "span 1",
		formType: "comboBox",
		catalog: "familia"
	},
	{
		name: "cuentaID",
		label: "Cuenta",
		gridColumn: "span 1",
		formType: "comboBox",
		catalog: "cuenta"
	},
	{
		name: "unidadID",
		label: "Unidad",
		gridColumn: "span 1",
		formType: "comboBox",
		catalog: "unidad"
	},
	{
		name: "descCorta",
		label: "Descripción Corta",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "descLarga",
		label: "Descripción Detallada",
		gridColumn: "span 2",
		formType: "textArea"
	},
	{
		name: "image",
		label: "Imagen",
		gridColumn: "span 2",
		formType: "image"
	}
];

export const editArticle = [
	{
		name: "clave",
		label: "Clave",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "tipoArtID",
		label: "Tipo",
		gridColumn: "span 1",
		formType: "comboBox",
		catalog: "tipo"
	},
	{
		name: "familia_id",
		label: "Familia",
		gridColumn: "span 1",
		formType: "comboBox",
		catalog: "familia"
	},
	{
		name: "cuenta_id",
		label: "Cuenta",
		gridColumn: "span 1",
		formType: "comboBox",
		catalog: "cuenta"
	},
	{
		name: "unidad_id",
		label: "Unidad",
		gridColumn: "span 1",
		formType: "comboBox",
		catalog: "unidad"
	},
	{
		name: "descCorta",
		label: "Descripción Corta",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "descLarga",
		label: "Descripción Detallada",
		gridColumn: "span 2",
		formType: "textArea"
	},
	{
		name: "image",
		label: "Imagen",
		gridColumn: "span 2",
		formType: "image"
	}
];

export const precioCompra = [
	{
		name: "precioCompra",
		label: "precioCompra",
		gridColumn: "span 1",
		formType: "text"
	},
	{
		name: "precioVenta",
		label: "precioCompra",
		gridColumn: "span 1",
		formType: "text"
	},
];