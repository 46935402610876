import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { CssBaseline, ThemeProvider, Box, Typography } from "@mui/material";
import { ColorModeContext, useMode } from "./theme/theme";

import Sidebar from "./components/includes/Sidebar";

import Dashboard from "./modules/dashboard";

import Articulo from "./modules/articles";
import DetallesArticulo from "./modules/articles/details";

import DetallesCompuesto from "./modules/compounds/details";

import Usuarios from "./modules/users";
import NuevoUsuario from "./modules/users/addNew";
import EditaUsuario from "./modules/users/edit";

import Catalogos from "./modules/catalogs";
import EditaCatalogo from "./modules/catalogs/edit";

import Projectos from "./modules/projects";
import DetallesProjecto from "./modules/projects/details";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';

library.add(fas);

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const location = useLocation();

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box
                    sx={{
                        marginLeft: isCollapsed ? '80px' : '250px',
                        padding: '20px',
                        display: "flex",
                        margin: 'auto',
                        maxWidth: '1780'
                    }}>
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            height: '100vh',
                            width: isCollapsed ? '80px' : '250px',
                            "& .pro-sidebar-inner": {
                                background: `${theme.palette.primary[600]} !important`,
                            },
                            "& .pro-icon-wrapper": {
                                backgroundColor: "transparent !important",
                            },
                            "& .pro-inner-item": {
                                padding: "5px 35px 5px 20px !important",
                            },
                            "& .pro-menu-item": {
                                color: "#f1f2f3 !important",
                            },
                            "& .pro-inner-item:hover": {
                                color: "#00aaff !important",
                            },
                            "& .pro-menu-item.active": {
                                color: "#00aaff !important",
                            },
                        }}
                    >
                        <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                    </Box>
                    <Box
                        sx={{
                            marginLeft: isCollapsed ? '80px' : '250px',
                            padding: '20px',
                            width: '100%'
                        }}
                    >
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/articles" element={<Articulo />} />
                            <Route path="/articles/details/:id" element={<DetallesArticulo />} />

                            <Route path="/compounds/details/:id" element={<DetallesCompuesto />} />

                            <Route path="/projects" element={<Projectos />} />
                            <Route path="/projects/details/:id" element={<DetallesProjecto />} />

                            <Route path="/users" element={<Usuarios />} />
                            <Route path="/users/addNew/" element={<NuevoUsuario />} />
                            <Route path="/users/edit/:id" element={<EditaUsuario />} />

                            <Route path="/catalogs" element={<Catalogos />} />
                            <Route path="/catalogs/edit/:id" element={<EditaCatalogo />} />
                        </Routes>
                    </Box>
                </Box>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
